<template>
  <div class="frame">
    <b-overlay spinner-type="grow" :show="!displayContent" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">{{ displayMessage }}</p>
        </div>
      </template>
      <div id="templates">
        <div id="template-title">
          Existing Templates
        </div>

        <b-table
          id="template-options"
          :items="items"
          :fields="fields"
          :busy="loadingTemplates"
          sticky-header="400px"
          :per-page="perPage"
          :current-page="currentPage"
          responsive="sm"
          striped
          outlined
          hover
          show-empty
          empty-text="There are no templates available for the selected data set"
        >
          <template #table-busy>
            <div class="text-center text-info">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-button size="sm" @click="editTemplate(row.item)" class="mr-1">
              Edit
            </b-button>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="Object.keys(items).length"
          :per-page="perPage"
          align="fill"
          size="sm"
          id="list-paginator"
        ></b-pagination>
      </div>

      <div id="form-title">{{ formTitle }}</div>

      <div id="user-form">
        <b-form @submit.stop.prevent="addTemplate">
          <b-form-group
            id="input-group-1"
            label-size="md"
            label-cols="4"
            label-cols-lg="4"
            label="Analysis ID:"
            label-for="input-1"
          >
            <b-form-select
              id="input-1"
              v-model="form.analysis"
              :options="analysisOptions"
              required
              size="sm"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label-size="md"
            label-cols="4"
            label-cols-lg="4"
            label="Data Set:"
            label-for="input-2"
          >
            <b-form-tags
              class="data-set-tags"
              input-id="data-set-tags"
              v-model="form.data_sets"
              tag-variant="info"
              button
              size="sm"
              :style="
                form.data_sets.length > 0
                  ? 'font-size: 18px;'
                  : 'font-size: 15px;'
              "
              separator=" "
              :placeholder="
                form.data_sets.length > 0
                  ? ''
                  : 'Select analyis to auto-populate data-sets'
              "
              disabled
            ></b-form-tags>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Template ID:"
            label-cols="4"
            label-cols-lg="4"
            label-size="md"
            label-for="input-3"
          >
            <b-input-group id="input-1" size="sm" :prepend="templateIdPrefix">
              <b-form-input
                id="input-3"
                v-model="form.template_id"
                required
                size="sm"
                placeholder="Unique template identifier"
                maxlength="32"
                pattern="[A-Za-z-_]{1,32}"
                title="Only alphabetical values are allowed, upto 32 letters"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group id="input-group-4" label-cols="4" label-cols-lg="4">
            <b-button v-if="!editForm" type="submit" variant="primary"
              >Submit</b-button
            >
            <b-button v-if="editForm" @click="updateTemplate" variant="primary"
              >Update</b-button
            >
            <b-button
              v-if="editForm"
              class="ml-2"
              @click="CancelUpdate"
              type="reset"
              variant="danger"
              >Cancel</b-button
            >
          </b-form-group>
        </b-form>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Template",
  watch: {
    "form.analysis": function(val) {
      var analysis_name = "";
      this.analysisOptions.forEach(element => {
        if (val === element.value) {
          analysis_name = element.text;
        }
      });

      var analysisNames = Object.keys(this.analyses);
      this.form.data_sets = [];
      analysisNames.forEach(element => {
        if (analysis_name === element) {
          this.form.data_sets = this.analyses[element].data_sets;
        }
      });
    }
  },
  data() {
    return {
      tabIndex: 0,
      editForm: false,
      displayContent: false,
      displayMessage: "Fetching existing templates..",
      formTitle: "Add Template",
      templateIdPrefix: "ch-template-",
      analyses: {}, //original response to fetch id from name
      analysisOptions: [],
      templateBeingEdited: undefined,
      form: {
        template_id: "",
        data_sets: [],
        analysis: ""
      },
      loadingTemplates: true,
      fields: [
        {
          key: "template_id",
          label: "Template ID",
          sortable: true
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false
        }
      ],
      items: [],
      perPage: 4,
      currentPage: 1
    };
  },
  methods: {
    makeToast(message, title, variant = "info") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 2500
      });
    },
    getTemplates: function() {
      this.loadingTemplates = true;
      let requestUrl = this.$store.state.apiUrl + "/admin/dashboard/template/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.items = [];

          response.data.forEach(element => {
            this.items.push({
              template_id: element
            });
          });

          this.loadingTemplates = false;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch dataSources",
            "warning"
          );
        });
    },
    getAnalyses: function() {
      let requestUrl = this.$store.state.apiUrl + "/admin/dashboard/analysis/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.analyses = response.data;
          var analysisNames = Object.keys(response.data);

          this.analysisOptions = [];
          for (var i = 0; i < analysisNames.length; i++) {
            this.analysisOptions.push({
              value: i,
              text: analysisNames[i]
            });
          }

          this.displayContent = true;
        })
        .catch(err => {
          console.log("Error: \n", err);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch dataSources",
            "warning"
          );
        });
    },
    editTemplate: function(item) {
      this.editForm = true;
      this.formTitle = "Update User";
      this.templateBeingEdited = item.template_id;

      this.form.template_id = item.template_id.substr(12);
    },
    addTemplate: function() {
      this.displayMessage = "Updating quicksight resource..";
      this.displayContent = false;

      var payload = {
        template_id: this.templateIdPrefix + this.form.template_id,
        data_set_ids: this.form.data_sets,
        analysis_id: ""
      };

      // fetch name of the selected analysis using drop down index
      // fetch id of the selected analysis using analysis name
      this.analysisOptions.forEach(element => {
        if (this.form.analysis === element.value) {
          payload.analysis_id = this.analyses[element.text].analysis_id;
        }
      });

      let requestUrl = this.$store.state.apiUrl + "/admin/dashboard/template/";
      axios
        .post(requestUrl, payload)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (response.status === 209) {
            this.makeToast(
              "Please select a unique identifier",
              "Template Already exists",
              "warning"
            );
          } else {
            this.makeToast(
              "New Template created",
              "Creation successful",
              "success"
            );

            this.form = {
              template_id: "",
              data_sets: [],
              analysis: ""
            };

            // Fetch a new list of data sets
            this.getTemplates();
          }

          this.displayContent = true;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Template creation failed",
            "warning"
          );

          this.displayContent = true;
        });
    },
    updateTemplate: function() {
      this.displayMessage = "Updating quicksight resource..";
      this.displayContent = false;

      var payload = {
        template_id: this.templateIdPrefix + this.form.template_id,
        data_set_ids: this.form.data_sets,
        analysis_id: ""
      };

      // fetch name of the selected analysis using drop down index
      // fetch id of the selected analysis using analysis name
      this.analysisOptions.forEach(element => {
        if (this.form.analysis === element.value) {
          payload.analysis_id = this.analyses[element.text].analysis_id;
        }
      });

      let requestUrl =
        this.$store.state.apiUrl +
        "/admin/dashboard/template/" +
        this.templateIdPrefix +
        this.form.template_id;
      axios
        .put(requestUrl, payload)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (response.status === 209) {
            this.makeToast(
              "Please select a unique identifier",
              "Template Already exists",
              "warning"
            );
          } else {
            this.makeToast(
              "Template updated",
              "Creation successful",
              "success"
            );

            this.form = {
              template_id: "",
              data_sets: [],
              analysis: ""
            };

            // Fetch a new list of data sets
            this.getTemplates();
          }

          this.displayContent = true;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Template update failed",
            "warning"
          );

          this.displayContent = true;
        });
    },
    CancelUpdate: function() {
      this.templateBeingEdited = undefined;
      this.form = {
        template_id: "",
        data_sets: [],
        analysis: ""
      };

      this.editForm = false;
      this.formTitle = "Add Template";
    }
  },
  mounted() {
    this.getAnalyses();
    this.getTemplates();
  }
};
</script>

<style scoped>
#template-title {
  text-align: left;
  font-size: 24px;
}

#form-title {
  text-align: left;
  margin: 25px 0 0 0px;
  font-size: 24px;
}

#user-form {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  text-align: left;
  padding: 10px;
  margin: 10px 0 0 0;
  font-size: 16px;
  width: 75%;
  min-width: 270px;
}

.b-form-tag-content > [span] {
  font-weight: 400 !important;
}
</style>
