<template>
  <div class="dashboard">
    <dashboard />
  </div>
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/Dashboard.vue";

export default {
  name: "Dashboard",
  components: {
    dashboard: Dashboard
  }
};
</script>
