<template>
  <div id="frame">
    <b-overlay spinner-type="grow" :show="!displayContent" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Updating agency info..</p>
        </div>
      </template>

      <div id="agency-list">
        <b-table
          :items="items"
          :fields="fields"
          :busy="loadingAgencies"
          sticky-header="400px"
          :per-page="perPage"
          :current-page="currentPage"
          responsive="sm"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          striped
          outlined
          hover
          show-empty
          empty-text="There are no registered Agencies"
        >
          <template #table-busy>
            <div class="text-center text-info">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-button size="sm" @click="editAgency(row.item)" class="mr-1">
              Edit
            </b-button>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="Object.keys(items).length"
          :per-page="perPage"
          align="fill"
          size="sm"
          id="list-paginator"
        ></b-pagination>
      </div>

      <div id="form-title">{{ formTitle }}</div>
      <div id="agency-form">
        <b-form @submit.stop.prevent="registerAgency">
          <b-form-group
            id="input-group-1"
            label="Agency Name:"
            label-cols="4"
            label-cols-lg="4"
            label-size="md"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.agency_name"
              required
              size="sm"
              placeholder="Agency Name"
              maxlength="32"
              pattern="^[a-z0-9_]{1,32}$"
              title="Only lowercase alphanumeric values, and underscores are allowed, upto 32 letters"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Is Active:"
            label-cols="4"
            label-cols-lg="4"
            label-size="md"
          >
            <b-form-checkbox v-model="form.active" switch />
          </b-form-group>

          <b-form-group id="input-group-3" label-cols="4" label-cols-lg="4">
            <b-button v-if="!editForm" type="submit" variant="primary"
              >Submit</b-button
            >
            <b-button
              v-if="editForm"
              @click="updateAgencyInfo"
              variant="primary"
              >Update</b-button
            >
            <b-button
              v-if="editForm"
              class="ml-2"
              @click="CancelUpdate"
              type="reset"
              variant="danger"
              >Cancel</b-button
            >
          </b-form-group>
        </b-form>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "Agency",
  data() {
    return {
      displayContent: true,
      formTitle: "Register New Agency",
      editForm: false,
      agencyBeingEdited: undefined,
      form: {
        agency_name: "",
        active: true
      },
      loadingAgencies: false,
      perPage: 4,
      currentPage: 1,
      sortBy: "email",
      sortDesc: false,
      fields: [
        {
          key: "agency_name",
          label: "Agency Name",
          thStyle: { "text-align": "left" },
          tdClass: "rule-name-list-class",
          sortable: true
        },
        {
          key: "active",
          label: "Active",
          thStyle: { "text-align": "left" },
          tdClass: "rule-name-list-class",
          sortable: true
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false
        }
      ],
      items: []
    };
  },
  computed: {
    ...mapState([])
  },
  watch: {},
  methods: {
    getAgencies: function() {
      this.loadingAgencies = true;
      let requestUrl = this.$store.state.apiUrl + "/admin/agencies/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.items = [];

          response.data.forEach(element => {
            this.items.push({
              agency_id: element.id,
              agency_name: element.name,
              active: element.active
            });
          });
          this.loadingAgencies = false;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch agencies",
            "warning"
          );
        });
    },
    registerAgency: function() {
      this.displayContent = false;
      let requestUrl = this.$store.state.apiUrl + "/admin/agencies/";
      axios
        .post(requestUrl, this.form)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.makeToast(
            "New Agency Registered",
            "Agency registration successful",
            "success"
          );
          this.form = {
            agency_name: "",
            active: true
          };
          this.agencyBeingEdited = false;
          this.editForm = false;
          this.displayContent = true;

          this.getAgencies();
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          if (err.response.status === 409)
            this.makeToast("Agency already exists", "Conflict", "warning");
          else
            this.makeToast(
              "Something went wrong, please contact administrator",
              "New Agency registration failed",
              "danger"
            );

          this.displayContent = true;
        });
    },
    updateAgencyInfo: function() {
      this.displayContent = false;
      let requestUrl =
        this.$store.state.apiUrl + "/admin/agencies/" + this.agencyBeingEdited;
      axios
        .put(requestUrl, this.form)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.makeToast("Agency info updated", "Update successful", "success");
          this.form = {
            agency_name: "",
            active: true
          };
          this.agencyBeingEdited = false;
          this.editForm = false;
          this.displayContent = true;

          this.getAgencies();
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Agency info update failed",
            "warning"
          );
        });
    },
    CancelUpdate: function() {
      this.agencyBeingEdited = undefined;
      this.form = {
        agency_name: "",
        active: true
      };
      this.editForm = false;
      this.formTitle = "Register New Agency";
    },
    editAgency: function(item) {
      this.editForm = true;
      this.formTitle = "Update Agency";
      this.agencyBeingEdited = item.agency_id;

      this.form = item;
      delete this.form["agency_id"];
    },
    makeToast(message, title, variant = "info") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 2500
      });
    }
  },
  mounted() {
    this.getAgencies();
  }
};
</script>

<style lang="scss" scoped>
#frame {
  height: calc(
    100vh - var(--top-nav-bar-height) - var(--footer-height) -
      var(--admin-tab-height)
  );
  overflow-y: scroll;
}

#form-title {
  text-align: left;
  margin: 25px 0 0 10px;
  font-size: 24px;
}

#agency-list {
  text-align: left;
  margin: 10px;
}

#list-paginator {
  text-align: right;
}

#agency-form {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  text-align: left;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  width: 75%;
  min-width: 270px;
}

hr.solid-line {
  border-top: 2px solid black;
  margin: 2px 4px 2px 0;
}
</style>

<!--
The following style cannot be scoped as "thClass", "tdClass"
does not not work if the classes are defined in scoped CSS"
-->
<style>
.rule-name-list-class {
  text-align: left;
  padding: 0;
}
</style>
