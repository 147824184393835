<template>
  <div class="frame">
    <div>
      <b-tabs content-class="mt-0" v-model="tabIndex" card vertical>
        <b-tab :title-link-class="linkClass(0)">
          <template v-slot:title>
            <b-icon
              class="icons"
              icon="collection"
              font-scale="1.2"
              shift-v="-1"
            />
            <strong> Data Set </strong>
          </template>

          <div class="content">
            <quicksightDataSet />
          </div>
        </b-tab>
        <b-tab :title-link-class="linkClass(1)" lazy>
          <template v-slot:title>
            <b-icon class="icons" icon="columns" font-scale="1" />
            <strong> Template </strong>
          </template>

          <div class="content">
            <quicksightTemplate />
          </div>
        </b-tab>
        <b-tab :title-link-class="linkClass(2)" lazy>
          <template v-slot:title>
            <b-icon class="icons" icon="display" font-scale="1" />
            <strong> Dashboard </strong>
          </template>

          <div class="content">
            <quicksightDashboard />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import DataSet from "@/components/QuicksightDataSet.vue";
import Template from "@/components/QuicksightTemplate.vue";
import Dashboard from "@/components/QuicksightDashboard.vue";

export default {
  name: "Quicksight",
  components: {
    quicksightDataSet: DataSet,
    quicksightTemplate: Template,
    quicksightDashboard: Dashboard
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-primary"];
      } else {
        return ["text-dark"];
      }
    }
  }
};
</script>

<style lang="scss">
#frame,
.content {
  height: calc(100vh - var(--top-nav-bar-height) - var(--footer-height));
  margin-top: 0 !important;
  overflow-y: scroll;
}

.nav-item {
  text-align: left;
}

#option-buttons {
  width: 100%;
}
</style>
