<template>
  <div id="frame">
    <b-overlay spinner-type="grow" :show="!displayContent" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Updating agency info..</p>
        </div>
      </template>

      <div id="agencies">
        <div id="agency-title">
          Agencies:
        </div>
        <b-form-select
          id="agency-options"
          v-model="selectedAgency"
          :options="AgencyOptions"
        ></b-form-select>
      </div>

      <div id="user-list">
        <b-table
          :items="items"
          :fields="fields"
          :busy="loadingUsers"
          sticky-header="400px"
          :per-page="perPage"
          :current-page="currentPage"
          responsive="sm"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          striped
          outlined
          hover
          show-empty
          empty-text="There are no registered Users for this Agency"
        >
          <template #table-busy>
            <div class="text-center text-info">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-button size="sm" @click="editUser(row.item)" class="mr-1">
              Edit
            </b-button>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="Object.keys(items).length"
          :per-page="perPage"
          align="fill"
          size="sm"
          id="list-paginator"
        ></b-pagination>
      </div>

      <div id="form-title">{{ formTitle }}</div>

      <div id="user-form">
        <b-form @submit.stop.prevent="registerUser">
          <b-form-group
            id="input-group-1"
            label="First Name:"
            label-cols="4"
            label-cols-lg="4"
            label-size="md"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.first_name"
              required
              size="sm"
              placeholder="First name"
              maxlength="32"
              pattern="[A-Za-z]{1,32}"
              title="Only alphabetical values are allowed, upto 32 letters"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label-size="md"
            label-cols="4"
            label-cols-lg="4"
            label="Last Name:"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="form.last_name"
              required
              size="sm"
              placeholder="Last name"
              maxlength="32"
              pattern="[A-Za-z]{1,32}"
              title="Only alphabetical values are allowed, upto 32 letters"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Email:"
            label-cols="4"
            label-cols-lg="4"
            label-size="md"
            label-for="input-3"
          >
            <b-form-input
              id="input-3"
              v-model="form.email"
              type="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              size="sm"
              placeholder="Enter email"
              title="Please enter a valid email address."
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-4"
            label-size="md"
            label-cols="4"
            label-cols-lg="4"
            label="Role:"
            label-for="input-4"
          >
            <b-form-select
              id="input-4"
              v-model="form.role"
              :options="roleOptions"
              required
              size="sm"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-4"
            label="Is Active:"
            label-cols="4"
            label-cols-lg="4"
            label-size="md"
          >
            <b-form-checkbox v-model="form.active" switch />
          </b-form-group>

          <b-form-group id="input-group-5" label-cols="4" label-cols-lg="4">
            <b-button v-if="!editForm" type="submit" variant="primary"
              >Submit</b-button
            >
            <b-button v-if="editForm" @click="updateUserInfo" variant="primary"
              >Update</b-button
            >
            <b-button
              v-if="editForm"
              class="ml-2"
              @click="CancelUpdate"
              type="reset"
              variant="danger"
              >Cancel</b-button
            >
          </b-form-group>
        </b-form>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "User",
  data() {
    return {
      displayContent: true,
      formTitle: "Register New User",
      loadingUsers: false,
      agencies: {},
      AgencyOptions: [],
      selectedAgency: "",
      roleOptions: [],
      editForm: false,
      userBeingEdited: undefined,
      form: {
        email: "",
        first_name: "",
        last_name: "",
        role: null,
        agency: null,
        active: true
      },
      loadingAgencies: false,
      perPage: 4,
      currentPage: 1,
      sortBy: "email",
      sortDesc: false,
      fields: [
        {
          key: "first_name",
          label: "First Name",
          sortable: true
        },
        {
          key: "last_name",
          label: "Last Name",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "role",
          label: "Role",
          sortable: true
        },
        {
          key: "agency",
          label: "Agency",
          sortable: true
        },
        {
          key: "active",
          label: "Active",
          sortable: true
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false
        }
      ],
      items: []
    };
  },
  computed: {
    ...mapState([])
  },
  watch: {
    selectedAgency: function() {
      this.getUsers();

      this.form = {
        email: "",
        first_name: "",
        last_name: "",
        role: null,
        agency: null,
        active: true
      };
    }
  },
  methods: {
    getAgencies: function() {
      this.loadingAgencies = true;
      let requestUrl = this.$store.state.apiUrl + "/admin/agencies/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.agencies = {};

          response.data.forEach(element => {
            this.AgencyOptions.push({
              value: element.id,
              text: element.name
            });

            this.agencies[element.id] = element.name;
          });
          this.selectedAgency = this.AgencyOptions[0].value;
          this.loadingAgencies = false;

          this.getUsers();
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch agencies",
            "warning"
          );
        });
    },
    getRoles: function() {
      let requestUrl = this.$store.state.apiUrl + "/admin/roles/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          response.data.forEach(element => {
            this.roleOptions.push({
              value: element.name,
              text: element.name
            });
          });
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch roles",
            "warning"
          );
        });
    },
    getUsers: function() {
      this.loadingUsers = true;

      let requestUrl =
        this.$store.state.apiUrl +
        "/admin/agencies/" +
        this.selectedAgency +
        "/users";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.items = [];

          response.data.forEach(element => {
            this.items.push({
              user_id: element.id,
              first_name: element.first_name,
              last_name: element.last_name,
              email: element.email,
              role: element.role,
              agency: element.agency,
              active: element.active
            });
          });

          this.loadingUsers = false;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch users",
            "warning"
          );
        });
    },
    registerUser: function() {
      this.displayContent = false;
      let requestUrl = this.$store.state.apiUrl + "/admin/users/";

      // Update 'agency' field to use value instead of id
      this.form.agency = this.agencies[this.selectedAgency];
      axios
        .post(requestUrl, this.form)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.makeToast("New User Registered", "Update successful", "success");

          this.form = {
            email: "",
            first_name: "",
            last_name: "",
            role: null,
            agency: null,
            active: true
          };

          this.userBeingEdited = false;
          this.editForm = false;
          this.displayContent = true;

          this.getUsers();
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          if (err.response.status === 409)
            this.makeToast("User already exists", "Conflict", "warning");
          else
            this.makeToast(
              "Something went wrong, please contact administrator",
              "New User registration failed",
              "danger"
            );
        });
    },
    updateUserInfo: function() {
      this.displayContent = false;
      let requestUrl =
        this.$store.state.apiUrl + "/admin/users/" + this.userBeingEdited;
      axios
        .put(requestUrl, this.form)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.makeToast("User info updated", "Update successful", "success");
          this.form = {
            email: "",
            first_name: "",
            last_name: "",
            role: null,
            agency: null,
            active: true
          };

          this.userBeingEdited = false;
          this.editForm = false;
          this.displayContent = true;

          this.getUsers();
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "User info update failed",
            "warning"
          );

          this.displayContent = false;
        });
    },
    CancelUpdate: function() {
      this.userBeingEdited = undefined;
      this.form = {
        email: "",
        first_name: "",
        last_name: "",
        role: null,
        agency: null,
        active: true
      };
      this.editForm = false;
      this.formTitle = "Register New User";
    },
    editUser: function(item) {
      this.editForm = true;
      this.formTitle = "Update User";
      this.userBeingEdited = item.user_id;
      console.log(item);

      this.form = item;
      // Update 'agency' field to use value instead of id
      this.form.agency = this.agencies[this.selectedAgency];
      delete this.form.user_id;
    },
    makeToast(message, title, variant = "info") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 2500
      });
    }
  },
  mounted() {
    this.getRoles();
    this.getAgencies();
  }
};
</script>

<style lang="scss" scoped>
#frame {
  height: calc(
    100vh - var(--top-nav-bar-height) - var(--footer-height) -
      var(--admin-tab-height)
  );
  overflow-y: scroll;
}

#agencies {
  display: flex;
  justify-content: space-between;
}

#agency-title {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0 0 10px;
}

#agency-options {
  margin: 5px 10px 0 20px;
}

#form-title {
  text-align: left;
  margin: 25px 0 0 10px;
  font-size: 24px;
}

#user-list {
  text-align: left;
  margin: 10px;
}

#list-paginator {
  text-align: right;
}

#user-form {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  text-align: left;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  width: 75%;
  min-width: 270px;
}
</style>
