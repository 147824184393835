<template>
  <div class="admin">
    <div>
      <b-tabs id="tabs" content-class="mt-0" v-model="tabIndex" card justified>
        <b-tab :title-link-class="linkClass(0)">
          <template v-slot:title>
            <b-icon icon="stoplights-fill" font-scale="1.25" shift-v="-1" />
            <strong> Agency </strong>
          </template>
          <agency />
        </b-tab>
        <b-tab :title-link-class="linkClass(1)" lazy>
          <template v-slot:title>
            <b-icon icon="people-fill" font-scale="1.2" />
            <strong> Users </strong>
          </template>
          <user />
        </b-tab>
        <b-tab :title-link-class="linkClass(2)" lazy>
          <template v-slot:title>
            <b-icon icon="file-spreadsheet-fill" font-scale="1.1" />
            <strong> Risk Index </strong>
          </template>
          <riskIndexManager />
        </b-tab>
        <b-tab :title-link-class="linkClass(3)" lazy>
          <template v-slot:title>
            <b-icon icon="columns-gap" font-scale="1.1" />
            <strong> Quicksight </strong>
          </template>
          <quicksight />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Agency from "@/components/Agency.vue";
import User from "@/components/User.vue";
import Quicksight from "@/components/Quicksight.vue";
import RiskIndexManager from "@/components/RiskIndexManager.vue";

export default {
  name: "Admin",
  components: {
    agency: Agency,
    user: User,
    quicksight: Quicksight,
    riskIndexManager: RiskIndexManager
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-primary"];
      } else {
        return ["text-dark"];
      }
    }
  },
  mounted() {}
};
</script>

<style scoped>
.tab-icon {
  padding-bottom: 20px;
}

div[role="tabpanel"] {
  padding: 0;
}
</style>
