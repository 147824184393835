<template>
  <div class="frame">
    <b-overlay spinner-type="grow" :show="!displayContent" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">{{ displayMessage }}</p>
        </div>
      </template>
      <div id="templates">
        <div id="dashboard-title">
          Existing Templates
        </div>

        <b-table
          id="dashboard-options"
          :items="items"
          :fields="fields"
          :busy="loadingDashboards"
          sticky-header="400px"
          :per-page="perPage"
          :current-page="currentPage"
          responsive="sm"
          striped
          outlined
          hover
          show-empty
          empty-text="There are no Dashboards available"
        >
          <template #table-busy>
            <div class="text-center text-info">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-button size="sm" @click="editDashboard(row.item)" class="mr-1">
              Edit
            </b-button>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="Object.keys(items).length"
          :per-page="perPage"
          align="fill"
          size="sm"
          id="list-paginator"
        ></b-pagination>
      </div>

      <div id="form-title">{{ formTitle }}</div>

      <div id="user-form">
        <b-form @submit.stop.prevent="addDashboard">
          <b-form-group
            id="input-group-1"
            label="Dashboard ID:"
            label-cols="4"
            label-cols-lg="4"
            label-size="md"
            label-for="input-1"
          >
            <b-input-group id="input-1" size="sm" :prepend="dashboardIdPrefix">
              <b-form-select
                id="input-3"
                v-model="form.dashboard_id"
                :options="agencyOptions"
                required
                size="sm"
              ></b-form-select>
            </b-input-group>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label-size="md"
            label-cols="4"
            label-cols-lg="4"
            label="Data Set:"
            label-for="input-2"
          >
            <b-form-select
              id="input-2"
              v-model="form.data_set_id"
              :options="dataSetOptions"
              required
              size="sm"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label-size="md"
            label-cols="4"
            label-cols-lg="4"
            label="Template ID:"
            label-for="input-3"
          >
            <b-form-select
              id="input-3"
              v-model="form.template_id"
              :options="templateOptions"
              required
              size="sm"
            ></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-5" label-cols="4" label-cols-lg="4">
            <b-button v-if="!editForm" type="submit" variant="primary"
              >Submit</b-button
            >
            <b-button v-if="editForm" @click="updateDashboard" variant="primary"
              >Update</b-button
            >
            <b-button
              v-if="editForm"
              class="ml-2"
              @click="CancelUpdate"
              type="reset"
              variant="danger"
              >Cancel</b-button
            >
          </b-form-group>
        </b-form>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Dashboard",
  data() {
    return {
      tabIndex: 0,
      editForm: false,
      formTitle: "Add Dashboard",
      displayContent: false,
      displayMessage: "Fetching existing dashboards..",
      dashboardBeingEdited: undefined,
      dataSetOptions: [],
      agencyOptions: [],
      templateOptions: [],
      dashboardIdPrefix: "ch-dash-",
      form: {
        dashboard_id: "",
        data_set_id: "",
        template_id: ""
      },
      loadingDashboards: true,
      fields: [
        {
          key: "dashboard_id",
          label: "Dashboard ID",
          sortable: true
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false
        }
      ],
      items: [],
      perPage: 4,
      currentPage: 1
    };
  },
  methods: {
    makeToast(message, title, variant = "info") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 2500
      });
    },
    getAgencies: function() {
      let requestUrl = this.$store.state.apiUrl + "/admin/agencies/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.agencyOptions = [];

          for (var i = 0; i < response.data.length; i++) {
            this.agencyOptions.push({
              value: i,
              text: response.data[i].name
            });
          }
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch agencies",
            "warning"
          );
        });
    },
    getTemplates: function() {
      let requestUrl = this.$store.state.apiUrl + "/admin/dashboard/template/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.templateOptions = [];
          for (var i = 0; i < response.data.length; i++) {
            this.templateOptions.push({
              value: i,
              text: response.data[i]
            });
          }

          this.displayContent = true;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch dataSources",
            "warning"
          );
        });
    },
    getDataSets: function() {
      let requestUrl = this.$store.state.apiUrl + "/admin/dashboard/data-set/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.dataSetOptions = [];
          for (var i = 0; i < response.data.length; i++) {
            this.dataSetOptions.push({
              value: i,
              text: response.data[i]
            });
          }
        })
        .catch(err => {
          console.log("Error: \n", err);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch dataSources",
            "warning"
          );
        });
    },
    getDashboards: function() {
      this.loadingDashboards = true;
      let requestUrl = this.$store.state.apiUrl + "/admin/dashboard/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.items = [];

          response.data.forEach(element => {
            this.items.push({
              dashboard_id: element
            });
          });

          this.loadingDashboards = false;
        })
        .catch(err => {
          console.log("Error: \n", err);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch dataSources",
            "warning"
          );
        });
    },
    editDashboard: function(item) {
      this.editForm = true;
      this.formTitle = "Update Dashboard";

      this.agencyOptions.forEach(element => {
        var agency = element.text.split("-");
        agency = agency[agency.length - 1];

        if (item.dashboard_id.includes(agency)) {
          this.form.dashboard_id = element.value;
          this.dashboardBeingEdited = element.text;
        }
      });

      this.dataSetOptions.forEach(element => {
        var agency = this.dashboardBeingEdited.split("-");
        agency = agency[agency.length - 1];
        if (element.text.includes(agency)) {
          this.form.data_set_id = element.value;
        }
      });
    },
    addDashboard: function() {
      this.displayMessage = "Updating quicksight resource..";
      this.displayContent = false;

      var payload = {
        dashboard_id: "",
        data_set_id: "",
        template_id: "",
        agency_name: ""
      };

      this.agencyOptions.forEach(element => {
        if (this.form.dashboard_id == element.value) {
          payload.dashboard_id = this.dashboardIdPrefix + element.text;
        }
      });

      var agency = payload.dashboard_id.split("-");
      payload.agency_name = agency[agency.length - 1];

      this.templateOptions.forEach(element => {
        if (this.form.template_id == element.value) {
          payload.template_id = element.text;
        }
      });

      this.dataSetOptions.forEach(element => {
        if (this.form.data_set_id == element.value) {
          payload.data_set_id = element.text;
        }
      });

      let requestUrl = this.$store.state.apiUrl + "/admin/dashboard/";
      axios
        .post(requestUrl, payload)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (response.status === 209) {
            this.makeToast(
              "Please select a unique identifier",
              "Dashboard Already exists",
              "warning"
            );
          } else {
            this.makeToast(
              "New Dashboard created",
              "Creation successful",
              "success"
            );

            this.form = {
              dashboard_id: "",
              data_set_id: "",
              template_id: ""
            };

            // Fetch a new list of data sets
            this.getDashboards();
          }

          this.displayContent = true;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "New User registration failed",
            "warning"
          );

          this.displayContent = true;
        });
    },
    updateDashboard: function() {
      this.displayMessage = "Updating quicksight resource..";
      this.displayContent = false;

      var payload = {
        data_set_id: "",
        template_id: "",
        agency_name: this.dashboardBeingEdited
      };

      this.templateOptions.forEach(element => {
        if (this.form.template_id == element.value) {
          payload.template_id = element.text;
        }
      });

      this.dataSetOptions.forEach(element => {
        if (this.form.data_set_id == element.value) {
          payload.data_set_id = element.text;
        }
      });

      let requestUrl =
        this.$store.state.apiUrl +
        "/admin/dashboard/" +
        this.dashboardIdPrefix +
        this.dashboardBeingEdited;

      axios
        .put(requestUrl, payload)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (response.status === 209) {
            this.makeToast(
              "Please select a unique identifier",
              "Dashboard Already exists",
              "warning"
            );
          } else {
            this.makeToast(
              "Dashboard updated",
              "Creation successful",
              "success"
            );

            this.form = {
              dashboard_id: "",
              data_set_id: "",
              template_id: ""
            };

            // Fetch a new list of data sets
            this.getTemplates();
          }

          this.displayContent = true;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "New User registration failed",
            "warning"
          );

          this.displayContent = true;
        });
    },
    CancelUpdate: function() {
      this.dashboardBeingEdited = undefined;
      this.form = {
        dashboard_id: "",
        data_set_id: "",
        template_id: ""
      };
      this.editForm = false;
      this.formTitle = "Add Template";
    }
  },
  mounted() {
    this.getDashboards();
    this.getDataSets();
    this.getTemplates();
    this.getAgencies();
  }
};
</script>

<style scoped>
#dashboard-title {
  text-align: left;
  font-size: 24px;
}

#form-title {
  text-align: left;
  margin: 25px 0 0 0px;
  font-size: 24px;
}

#user-form {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  text-align: left;
  padding: 10px;
  margin: 10px 0 0 0;
  font-size: 16px;
  width: 75%;
  min-width: 270px;
}
</style>
