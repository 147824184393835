<template>
  <div class="frame">
    <b-overlay spinner-type="grow" :show="!displayContent" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">{{ displayMessage }}</p>
        </div>
      </template>

      <div id="data-sets">
        <div id="data-sets-title">
          Data Sets:
        </div>

        <b-table
          id="data-set-options"
          :items="items"
          :fields="fields"
          :busy="loadingDataSets"
          sticky-header="400px"
          :per-page="perPage"
          :current-page="currentPage"
          responsive="sm"
          striped
          outlined
          hover
          show-empty
          empty-text="There are no data sets associated with the selected data source"
        >
          <template #table-busy>
            <div class="text-center text-info">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="Object.keys(items).length"
          :per-page="perPage"
          align="fill"
          size="sm"
          id="list-paginator"
        ></b-pagination>
      </div>

      <div id="form-title">{{ formTitle }}</div>

      <div id="data-set-form">
        <b-form @submit.stop.prevent="addDataSet">
          <b-form-group
            id="input-group-1"
            label-size="md"
            label-cols="4"
            label-cols-lg="4"
            label="Agency Name:"
            label-for="input-1"
          >
            <b-form-select
              id="input-1"
              v-model="form.agency_name"
              :options="agencyOptions"
              required
              size="sm"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label-size="md"
            label-cols="4"
            label-cols-lg="4"
            label="Data Source:"
            label-for="input-2"
          >
            <b-form-select
              id="input-2"
              v-model="form.data_source"
              :options="dataSourceOptions"
              required
              size="sm"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Data set ID:"
            label-cols="4"
            label-cols-lg="4"
            label-size="md"
            label-for="input-3"
          >
            <b-input-group id="input-3" size="sm" :prepend="dataSetIdPrefix">
              <b-form-input
                id="input-3"
                v-model="form.data_set"
                required
                size="sm"
                placeholder="Agency Name"
                disabled
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group id="input-group-4" label-cols="4" label-cols-lg="4">
            <b-button type="submit" variant="primary">Submit</b-button>
          </b-form-group>
        </b-form>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DataSet",
  watch: {
    "form.agency_name": function(val) {
      this.agencyOptions.forEach(element => {
        if (val === element.value) {
          this.form.data_set = element.text;
        }
      });
    }
  },
  data() {
    return {
      tabIndex: 0,
      dataSetIdPrefix: "ch-dataset-",
      displayContent: false,
      displayMessage: "Fetching existing data sets..",
      formTitle: "Add Data Set",
      form: {
        data_set: "",
        data_source: "",
        agency_name: ""
      },
      dataSources: {}, //original response to fetch id from name
      dataSourceOptions: [],
      agencyOptions: [],
      loadingDataSets: true,
      fields: [
        {
          key: "data_set",
          label: "Data Set ID",
          sortable: true
        }
      ],
      items: [],
      perPage: 4,
      currentPage: 1
    };
  },
  methods: {
    makeToast(message, title, variant = "info") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 2500
      });
    },
    getDataSources: function() {
      let requestUrl =
        this.$store.state.apiUrl + "/admin/dashboard/data-source/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.dataSources = response.data;
          var dataSourceNames = Object.keys(response.data);

          this.dataSourceOptions = [];
          for (var i = 0; i < dataSourceNames.length; i++) {
            this.dataSourceOptions.push({
              value: i,
              text: dataSourceNames[i]
            });
          }

          // Select first element as default
          this.form.data_source = 0;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch dataSources",
            "warning"
          );
        });
    },
    getAgencies: function() {
      let requestUrl = this.$store.state.apiUrl + "/admin/agencies/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.agencyOptions = [];

          for (var i = 0; i < response.data.length; i++) {
            this.agencyOptions.push({
              value: i,
              text: response.data[i].name
            });
          }

          // Select first element as default
          this.form.agency_name = 0;
          this.form.data_set = this.agencyOptions[0].text;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch agencies",
            "warning"
          );
        });
    },
    getDataSets: function() {
      this.loadingDataSets = true;
      let requestUrl = this.$store.state.apiUrl + "/admin/dashboard/data-set/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.items = [];

          response.data.forEach(element => {
            this.items.push({
              data_set: element
            });
          });

          this.loadingDataSets = false;
          this.displayContent = true;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch dataSources",
            "warning"
          );
        });
    },
    addDataSet: function() {
      this.displayMessage = "Updating quicksight resource..";
      this.displayContent = false;

      var payload = {
        data_set_id: this.dataSetIdPrefix + this.form.data_set,
        data_source_id: "",
        agency_name: ""
      };

      // convert data source name selection to data source id
      this.dataSourceOptions.forEach(element => {
        if (this.form.data_source == element.value) {
          payload.data_source_id = this.dataSources[
            element.text
          ].data_source_id;
        }
      });

      // convert agency name index selection to agency name
      this.agencyOptions.forEach(element => {
        if (this.form.agency_name == element.value) {
          payload.agency_name = element.text;
        }
      });

      let requestUrl = this.$store.state.apiUrl + "/admin/dashboard/data-set/";
      axios
        .post(requestUrl, payload)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (response.status === 209) {
            this.makeToast(
              "Please select a unique identifier",
              "Data Set Already exists",
              "warning"
            );
          } else {
            this.makeToast(
              "New Data Set created",
              "Creation successful",
              "success"
            );

            this.form = {
              data_set: "",
              data_source: "",
              agency_name: ""
            };
            // Fetch a new list of data sets
            this.getDataSets();
          }

          this.displayContent = true;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "New Data Set registration failed",
            "warning"
          );

          this.displayContent = true;
        });
    }
  },
  mounted() {
    this.getDataSources();
    this.getAgencies();
    this.getDataSets();
  }
};
</script>

<style lang="scss" scoped>
#data-sets-title {
  text-align: left;
  font-size: 24px;
}

#form-title {
  text-align: left;
  margin: 25px 0 0 0px;
  font-size: 24px;
}

#data-set-form {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  text-align: left;
  padding: 10px;
  margin: 10px 0 0 0;
  font-size: 16px;
  width: 75%;
  min-width: 270px;
}
</style>
