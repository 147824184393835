<template>
  <div id="dashboard">
    <div id="dashboard-frame" />

    <b-spinner
      variant="info"
      v-if="!urlReady"
      type="grow"
      style="width: 3rem; height: 3rem;"
      id="dashboard-loader"
    ></b-spinner>
  </div>
</template>

<script>
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import axios from "axios";
export default {
  name: "Dashboard",
  data() {
    return {
      embedURL: "",
      urlReady: false
    };
  },
  methods: {
    fetchEmbedURL: function() {
      let requestUrl = this.$store.state.apiUrl + "/dashboard/url";
      axios
        .get(requestUrl)
        .then(response => {
          this.embedURL = response.data.dashboard_url;
          this.addDashboard();
        })
        .catch(err => console.log(err));
    },
    addDashboard: function() {
      var options = {
        url: this.embedURL,
        container: "#dashboard-frame",
        height: "100%",
        width: "100%",
        locale: "en-US",
        footerPaddingEnabled: true
      };
      var dash = embedDashboard(options);
      dash.on("load", this.showDashboard());
    },
    showDashboard: function() {
      this.urlReady = true;
    }
  },
  mounted() {
    this.fetchEmbedURL();
  }
};
</script>

<style scoped>
#dashboard-loader {
  position: absolute;
  top: 45%;
  z-index: 1004;
}

#dashboard-frame {
  height: calc(100vh - var(--top-nav-bar-height) - var(--footer-height));
  z-index: 1002;
}
</style>
