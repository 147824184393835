<template>
  <div class="frame">
    <b-overlay spinner-type="grow" :show="!displayContent" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Fetching Risk Index weights..</p>
        </div>
      </template>

      <div id="details">
        <div id="agencies">
          <div id="agency-title">
            Agencies:
          </div>
          <b-form-select
            id="agency-options"
            v-model="selectedAgency"
            :options="AgencyOptions"
          ></b-form-select>
        </div>

        <div id="risk-index-factors">
          <div id="risk-index-factors-title">
            Factors:
          </div>
          <b-form
            id="risk-index-factors-form"
            @submit.stop.prevent="updateRiskIndexWeights"
          >
            <div id="risk-index-factors-options">
              <b-form-group
                :id="'input-group-' + param"
                :label="labelFomatter(param)"
                label-cols="4"
                label-cols-lg="4"
                label-size="md"
                :label-for="'input-label-' + param"
                v-for="param in Object.keys(this.form)"
                :key="param"
                v-show="param != 'agency_id'"
              >
                <b-form-input
                  :id="'input-label-' + param"
                  class="input-fields"
                  v-model="form[param]"
                  required
                  size="sm"
                  :placeholder="
                    'Please enter a value for ' + labelFomatter(param)
                  "
                  maxlength="32"
                  pattern="^[0-9]{0,2}(\.{0,1}\d{0,2})$"
                  title="Only Integer/Decimal values allowed (up to 9999 and 2 precision scale)"
                ></b-form-input>
              </b-form-group>
            </div>

            <b-form-group id="input-group-submit">
              <b-button id="submit-button" type="submit" variant="primary"
                >Update Risk Index Weights</b-button
              >
            </b-form-group>
          </b-form>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RiskIndexManager",
  components: {},
  watch: {
    selectedAgency: function(agencyId) {
      this.displayContent = false;
      this.getRiskIndexWeights(agencyId);
    }
  },
  data() {
    return {
      agencies: {},
      AgencyOptions: [],
      loading: false,
      selectedAgency: "",
      displayContent: false,
      form: {
        agency_id: "",
        combined_severity: "",
        crash_rate: "",
        dark_condition: "",
        dui: "",
        fatality: "",
        hit_and_run: "",
        indicators_multiplier: "",
        injury: "",
        is_curved: "",
        lane_departure: "",
        num_lanes: "",
        road_not_dry: "",
        vru: ""
      }
    };
  },
  methods: {
    getAgencies: function() {
      this.loading = true;
      let requestUrl = this.$store.state.apiUrl + "/admin/agencies/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.agencies = {};

          response.data.forEach(element => {
            this.AgencyOptions.push({
              value: element.id,
              text: element.name
            });

            this.agencies[element.id] = element.name;
          });
          this.selectedAgency = this.AgencyOptions[0].value;
          this.loading = false;
        })
        .catch(err => {
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch agencies",
            "warning"
          );
        });
    },
    getRiskIndexWeights: function(agencyId) {
      this.loading = true;
      let requestUrl =
        this.$store.state.apiUrl +
        "/admin/agencies/" +
        agencyId +
        "/risk-index-weight/";
      axios
        .get(requestUrl)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.form = response.data;
          this.loading = false;

          this.displayContent = true;
        })
        .catch(err => {
          console.log("Error: \n", err);
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not fetch agencies",
            "warning"
          );
        });
    },
    updateRiskIndexWeights: function() {
      this.displayContent = false;
      let agency_id = this.form.agency_id;
      let requestUrl =
        this.$store.state.apiUrl +
        "/admin/agencies/" +
        agency_id +
        "/risk-index-weight/";

      // remove this field because it should not be part of the request body
      delete this.form["agency_id"];
      axios
        .put(requestUrl, this.form)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.makeToast(
            "Risk Index Weights Updated",
            "Update successful",
            "success"
          );

          // Add this field back
          this.form.agency_id = agency_id;
          this.displayContent = true;
        })
        .catch(err => {
          console.log(err);
          console.log("Error: \n", err.response);
          this.makeToast(
            "Something went wrong, please contact administrator",
            "Could not update Risk Indexes",
            "danger"
          );

          this.displayContent = true;
        });
    },
    labelFomatter: function(label) {
      let words = label.split("_");
      words = words.map(word => word.charAt(0).toUpperCase() + word.substr(1));

      return words.join(" ");
    },
    makeToast(message, title, variant = "info") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 2500
      });
    }
  },
  mounted: function() {
    this.getAgencies();
  }
};
</script>

<style lang="scss">
#frame,
.content {
  height: calc(100vh - var(--top-nav-bar-height) - var(--footer-height));
}

#details {
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
}

.nav-item {
  text-align: left;
}

#agencies {
  text-align: left;
}

#agency-title {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0 0 10px;
}

#agency-options {
  margin: 10px 0 0 10px;
}

#risk-index-factors {
  text-align: left;
}

#risk-index-factors-title {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0 0 40px;
  height: 30px;
}

#risk-index-factors-form {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  text-align: left;
  padding: 10px 10px 0 10px;
  margin: 10px 20px 0 40px;
  font-size: 16px;
}

#risk-index-factors-options {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  height: calc(
    100vh - var(--top-nav-bar-height) - var(--footer-height) - 200px
  );
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

#input-group-submit {
  padding: 15px 0 10px 0;
}
</style>
